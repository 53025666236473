import React from 'react'
import logo from '../assets/images/logo.png'

export default function Footer() {
  return (
    <footer className="footer">
      <p style={{
    color: 'var(--vortex-orange)',
    boxShadow: '0px 0px 10px #000000',
    textAlign: 'center',
    fontSize: '1.4rem',
    lineHeight: '1.5',
    maxWidth: '100%',
    margin: '0 auto',
    padding: '10px',
}}>
  ASOCIACIÓN CIVIL DEPORTIVA A8 Decreto N° 1921
</p>

    <div className="footer-bottom">
      <div className="container">

        <p className="copyright">
        &copy; 2023 - { new Date().getFullYear() } Vortex Club Deportivo. Todos Los Derechos Reservados. by <a href="https://proyecta.tech" className="copyright-link">Proyecta</a>
        </p>

          <img
          src={logo}
          width="240"
          height="21"
          loading="lazy"
          style={{filter:"grayscale(80%) contrast(10%)"}}
          alt="logo vortex footer"
          className="footer-bottom-img"/>
      </div>
    </div>

  </footer>
  )
}
